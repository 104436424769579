
.sberstrahovaniye_cover {
    width: 100%;
    height: 587px;
}


.sberstrahovaniye_pack__status {
    width: 100%;
    margin: 100px 0 0 0;
}


@media screen and (max-width: 1240px) {

}

@media screen and (max-width: 800px) {
    .sberstrahovaniye_task-pic {
        width: 100%;
        min-height: 300px;
    }
    
    .sberstrahovaniye_solution-pic {
        width: 100%;
        min-height: 300px;
        margin-top: 100px;
    }
}